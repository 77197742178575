import appConfig from '../../config/app';
import { getAuthHeaders } from './auth';

export async function fetchUserCheckins(user_id) {
  try {
    const response = await fetch(
      `${appConfig.baseApiUrl}/api/v1/users/${user_id}/checkins`,
      {
        ...getAuthHeaders(),
      }
    );
    return await response.json();
  } catch (e) {
    console.log(e);
    throw e;
  }
}
